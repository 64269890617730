<template>
  <div class="container">
    <div class="item-title-line">
      发票管理
    </div>
    <el-tabs v-model="activeName" class="cent-tabs">
      <el-tab-pane label="开票记录" name="first">
        <!--        开发票-->
        <div v-if="!IsDrawBill">
          <div class="kpjl-border" style="height: 160px;">

            <div class="kpjl-item">
              <p style="font-weight: 600;">可开票金额</p>
              <p class="blue-p item-p" v-cloak>￥ {{moneyFormats(invoiceAmount.surplus)}}</p>
              <el-button color="#0052D9" style="margin-top: 30px" @click="drawBillClick">去开票</el-button>
            </div>

            <div class="kpjl-item">
              <p class="text-grey" style="text-indent: 2em">总计消费可开票</p>
              <p class="item-p" v-cloak>
                <span class="symbol">=</span>￥ {{moneyFormats(invoiceAmount.amount)}}
              </p>
            </div>

            <div class="kpjl-item">
              <p class="text-grey" style="text-indent: 2em">历史已开票</p>
              <p class="item-p" v-cloak>
                <span class="symbol">-</span>￥ {{moneyFormats(historicalAmount)}}
              </p>
            </div>

            <div class="kpjl-item">
              <p class="text-grey" style="text-indent: 2em">本月不可开</p>
              <p class="item-p" v-cloak>
                <span class="symbol">-</span>￥ {{moneyFormats(invoiceAmount.mayNot)}}
              </p>
            </div>

          </div>

          <!--        发票简要信息-->
          <div class="kpjl-border" style="min-height: 120px;margin-top: 10px">
            <p style="font-weight: 600;width: 100%;text-align: left">发票信息及地址管理</p>

            <div class="kpjl-item item-flex-centet">
              <p class="text-grey">抬头（默认）：</p>
              <p v-text="ruleForm.header"></p>
              <span class="blue-p" style="margin-left: 30px;cursor: pointer" @click="routerCard('second')">编辑</span>
            </div>

            <div class="kpjl-item item-flex-centet">
              <p class="text-grey">税号：</p>
              <p v-text="ruleForm.code"></p>
              <span class="blue-p" style="margin-left: 30px;cursor: pointer" @click="routerCard('second')">编辑</span>
            </div>

            <div class="kpjl-item item-flex-centet">
              <p class="text-grey">邮寄地址（默认）：</p>
              <p v-text="emailAddressForm.region_cn + '/' + emailAddressForm.address"></p>
              <span class="blue-p" style="margin-left: 30px;cursor: pointer" @click="routerCard('third')">编辑</span>
            </div>

            <div class="kpjl-item item-flex-centet">
              <p class="text-grey">电子邮箱（默认）：</p>
              <p v-text="emailForm.email"></p>
              <span class="blue-p" style="margin-left: 30px;cursor: pointer" @click="routerCard('fourth')">编辑</span>
            </div>

          </div>
          <!--        发票表格-->
          <div class="table-div">

            <div class="title-tool department-tool">

              <div style="display: flex;width: 100%;">
                <div class="tool-item">
                  <div class="tool-label">
                    搜索：
                  </div>
                  <el-input v-model="search" placeholder="请输入账号名称" style="width: 100%;"/>
                </div>

                <div class="tool-item">
                  <div class="tool-label">
                    是否开票：
                  </div>
                  <el-select v-model="state" style="width: 100%" placeholder="选择是否开票">
                    <el-option
                        v-for="item in typeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </div>

                <div class="tool-item" style="color: #ffffff;">
                  <el-button color="#2968D4" type="primary" @click="selectBtn">查 询</el-button>
                  <el-button @click="resetBtn">重置</el-button>
                </div>
              </div>
            </div>

            <el-table :data="userTableData" style="width: 100%;margin-top: 20px" :row-style="{height:'65px'}">
              <el-table-column prop="add_time" label="申请时间" align="center" show-overflow-tooltip/>
              <el-table-column prop="remit_name" label="账户名称" align="center"/>
              <el-table-column prop="remit_code" label="汇款账户" align="center" show-overflow-tooltip/>
              <el-table-column prop="price" label="发票总额（元）" align="center" show-overflow-tooltip/>
              <el-table-column label="发票性质">
                <template #default="scope">
                  <span v-if="!scope.row.invoice_type">
                    --
                  </span>
                  <span>
                    {{ scope.row.invoice_type }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="是否开票">
                <template #default="scope">
                  <el-tag class="ml-2" type="info" v-if="scope.row.invoice =='0'">未开票</el-tag>
                  <el-tag class="ml-2"  v-else-if="scope.row.invoice =='1'">开票中</el-tag>
                  <el-tag class="ml-2" type="success" v-else-if="scope.row.invoice =='2'">已开票</el-tag>
                </template>
              </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="pagination-box" style="bottom: 0">
              <el-pagination
                  v-model:currentPage="nowPage"
                  v-model:page-size="pageLimit"
                  layout="total,prev, pager, next, jumper"
                  :total="tableTotal"
                  @current-change="handleCurrentChange"
              />
            </div>
          </div>

        </div>

        <!--          去开发票-->
        <div v-else>
          <el-steps :active="stepsActive" align-center finish-status="finish" process-status="wait">
            <el-step title="第一步" description="选择待开票数据" />
            <el-step title="第二步" description="确定发票信息和地址" />
          </el-steps>
<!--          选择要开的发票-->
          <div v-if="stepsActive === 1">
            <div class="table-div" style="margin-top: 15px;height: 540px">
              <el-table :data="invoiceTableData" style="width: 100%;margin-top: 20px"
                        @selection-change="handleSelectionChange"
                        @row-click="handleRowClick"
                        ref="multipleTable"
                        :row-style="{height:'65px'}">
                <el-table-column type="selection" width="30" />
                <el-table-column prop="remit_name" label="账户名称" align="center" show-overflow-tooltip/>
                <el-table-column prop="remit_code" label="汇款账号" align="center" show-overflow-tooltip/>
                <el-table-column prop="renew_type" label="类型" align="center" show-overflow-tooltip/>
                <el-table-column prop="price" label="订单实付金额" align="center" show-overflow-tooltip/>
                <el-table-column prop="price" label="可开票金额" align="center" show-overflow-tooltip/>
              </el-table>

              <!-- 分页 -->
              <div class="pagination-box ">
                <el-pagination
                    v-model:currentPage="invoiceNowPage"
                    v-model:page-size="invoicePageLimit"
                    layout="total,prev, pager, next, jumper"
                    :total="invoiceTableTotal"
                    @current-change="invoiceHandleCurrentChange"
                />
              </div>

            </div>
            <!--          下一步按钮-->
            <div class="pagination-flex" style="margin-top: 15px">
              <div style="font-size: 22px;display: flex;align-items: center;">
                <p class="black-p">待开票金额</p>
                <p class="blue-p" style="margin-left: 15px" v-cloak>￥{{moneyFormats(amountToBeInvoiced)}}</p>
                <p class="text-grey" style="font-size: 14px;margin-left: 20px">
                  已选 <span v-text="selectionChangeList.length"></span> 个订单
<!--                  共 <span>￥0.00</span>-->
<!--                  ，欠票金额 <span>￥0.00</span>-->
                </p>
              </div>
              <div>
                <el-button color="#2968D4" @click="lastSteps">上一步</el-button>
                <el-button color="#2968D4" @click="nextSteps">下一步</el-button>
              </div>
            </div>
          </div>

          <div v-else>

            <p class="title-p">
              请确认发票抬头
              <span class="blue-p" style="margin-left: 8px;cursor: pointer;font-size: 13px" @click="routerCard('second')">编辑</span>
            </p>

            <el-descriptions
                class="margin-top"
                :column="3"
                border
            >
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon>
                      <user/>
                    </el-icon>
                    发票抬头
                  </div>
                </template>
                <span v-text="ruleForm.header"></span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon><DocumentCopy /></el-icon>
                    发票类型
                  </div>
                </template>
                <span v-text="ruleForm.type"></span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon><CreditCard /></el-icon>
                    纳税人识别号
                  </div>
                </template>
                <span v-text="ruleForm.code"></span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon>
                      <office-building/>
                    </el-icon>
                    基本开户银行
                  </div>
                </template>
                <span v-text="ruleForm.bank_name"></span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon>
                      <tickets/>
                    </el-icon>
                    基本开户账号
                  </div>
                </template>
                <span v-text="ruleForm.bank_code"></span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon>
                      <iphone/>
                    </el-icon>
                    企业注册电话
                  </div>
                </template>
                <span v-text="ruleForm.phone"></span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon>
                      <location/>
                    </el-icon>
                    企业注册地址
                  </div>
                </template>
                <span v-text="ruleForm.bank_address"></span>
              </el-descriptions-item>
            </el-descriptions>

            <p class="title-p" style="margin-top: 30px">请选择发票介质</p>
            <div style="text-align: left">
              <el-radio-group v-model="invoicingForm.type">
                <el-radio label="0">电子发票普票（开票快）</el-radio>
                <el-radio label="1">纸质发票（提交申请成功后，将于3个工作日内为您开具邮寄）</el-radio>
              </el-radio-group>
            </div>

            <p class="title-p" style="margin-top: 30px">请确认邮寄地址
              <span class="blue-p" style="margin-left: 8px;cursor: pointer;font-size: 13px" @click="routerCard('third')">编辑</span>
            </p>

            <el-descriptions
                class="margin-top"
                :column="3"
                border
            >
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon>
                      <user/>
                    </el-icon>
                    收件人
                  </div>
                </template>
                <span v-text="emailAddressForm.name"></span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon>
                      <iphone/>
                    </el-icon>
                    联系方式
                  </div>
                </template>
                <span v-text="emailAddressForm.phone"></span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon><Paperclip /></el-icon>
                    电子邮箱
                  </div>
                </template>
                15158901040002783
              </el-descriptions-item>
              <el-descriptions-item>
                <template #label>
                  <div class="cell-item">
                    <el-icon>
                      <location/>
                    </el-icon>
                    地址
                  </div>
                </template>
                <span v-text="emailAddressForm.region_cn + '/' + emailAddressForm.address"></span>
              </el-descriptions-item>
            </el-descriptions>

            <p class="title-p" style="margin-top: 30px">填写发票备注（选填）</p>
            <div>
              <el-input
                  v-model="invoicingForm.des"
                  :autosize="{ minRows: 4}"
                  type="textarea"
                  placeholder="填写后打印在发票的备注内容区域"
              />
            </div>

          </div>


          <!--          确认提交按钮-->
          <div class="pagination-flex" style="margin-top: 50px" v-if="stepsActive === 2">
            <div style="font-size: 22px;display: flex;align-items: center;">
              <p class="black-p">待开票金额</p>
              <p class="blue-p" style="margin-left: 15px" v-cloak>￥{{moneyFormats(amountToBeInvoiced)}}</p>
              <p class="text-grey" style="font-size: 14px;margin-left: 20px">
                此纸质发票预计 <spam class="blue-p">3个工作日</spam> 内用顺风寄出
              </p>
              <p style="color: #E34D59;font-size: 14px;margin-left: 10px">（开票金额不足10元将使用顺丰到付）</p>
            </div>
            <div style="font-size: 22px;display: flex;align-items: center;">
              <el-button color="#2968D4" @click="confirmSubmission" >确 认</el-button>
              <el-button @click="cancelBtn">上一步</el-button>
            </div>
          </div>

        </div>

      </el-tab-pane>
      <el-tab-pane label="发票抬头" name="second">

        <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="120px"
            class="cent-ruleForm"
        >
          <el-form-item label=" 发票抬头" prop="header">
            <el-input v-model="ruleForm.header" placeholder="请输入发票抬头"/>
          </el-form-item>

          <el-form-item label=" 默认发票类" prop="type">
            <el-radio-group v-model="ruleForm.type">
              <el-radio label="增值税普通发票">增值税普通发票</el-radio>
              <el-radio label="增值税专用发票">增值税专用发票</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-alert type="info" show-icon :closable="false">
            <p>请与贵公司财务人员核实后，仔细填写准确的三证合一后的统一社会信用代码，否则将影响后续发票的正常使用</p>
          </el-alert>

          <el-form-item label="统一社会信代码" prop="code">
            <el-input v-model="ruleForm.code" placeholder="请输入统一社会信用代码"/>
          </el-form-item>

          <el-form-item label="基本开户账号" prop="bank_code">
            <el-input v-model="ruleForm.bank_code" placeholder="请输入基本开户账号"/>
          </el-form-item>

          <el-form-item label="企业场所地址" prop="bank_address">
            <el-input v-model="ruleForm.bank_address" placeholder="请输入企业场所地址"/>
          </el-form-item>

          <el-form-item label="开户银行名称" prop="bank_name">
            <el-input v-model="ruleForm.bank_name" placeholder="请输入开户银行名称"/>
          </el-form-item>

          <el-form-item label="注册固定电话" prop="phone">
            <el-input v-model="ruleForm.phone" placeholder="请输入注册固定电话"/>
          </el-form-item>

          <!--                保存按钮-->
          <div class="btn-box">
            <el-row class="mb-4" style="justify-content: center;">
              <el-button color="#0052D9" @click="submitHeader">保 存</el-button>
              <el-button type="info" @click="resetForm('ruleFormRef')">重 置</el-button>
            </el-row>
          </div>

        </el-form>


      </el-tab-pane>
      <el-tab-pane label="邮寄地址" name="third">


        <el-form
            ref="emailAddressFormRef"
            :model="emailAddressForm"
            :rules="emailAddressRules"
            label-width="120px"
            class="cent-ruleForm"
        >
          <el-form-item label="收件人：" prop="name">
            <el-input v-model="emailAddressForm.name" placeholder="请输入收件人"/>
          </el-form-item>
          <el-form-item label="选择地区：" prop="region">
            <el-cascader
                :options="options"
                ref="cascaderArea"
                style="width: 100%;"
                filterable
                v-model="emailAddressForm.region"
                @change="handleChange">
            </el-cascader>
          </el-form-item>
          <el-form-item label=" 详细地址：" prop="address">
            <el-input v-model="emailAddressForm.address" placeholder="请输入详细地址"/>
          </el-form-item>
          <el-form-item label="联系电话：" prop="phone">
            <el-input v-model.number="emailAddressForm.phone" placeholder="请输入联系电话"/>
          </el-form-item>
          <el-form-item label=" 邮政编码：" prop="postal_code">
            <el-input v-model="emailAddressForm.postal_code" placeholder="请输入邮政编码"/>
          </el-form-item>
<!--          <el-form-item label="" prop="checked">-->
<!--            <el-checkbox-group v-model="emailAddressForm.checked">-->
<!--              <el-checkbox label="设为默认地址" name="1"/>-->
<!--            </el-checkbox-group>-->
<!--          </el-form-item>-->
          <!--                保存按钮-->
          <div class="btn-box">
            <el-row class="mb-4" style="justify-content: center;">
              <el-button color="#0052D9" @click="submitEmailAddress">保 存</el-button>
              <el-button type="info" @click="resetForm('emailAddressFormRef')">重 置</el-button>
            </el-row>
          </div>

        </el-form>

      </el-tab-pane>
      <el-tab-pane label="电子邮箱" name="fourth">

        <el-form
            ref="emailFormRef"
            :model="emailForm"
            :rules="emailRules"
            label-width="80px"
            class="cent-ruleForm"
        >
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="emailForm.email" placeholder="请输入邮箱"/>
          </el-form-item>
<!--          <el-form-item label="" prop="checked">-->
<!--            <el-checkbox-group v-model="emailForm.checked">-->
<!--              <el-checkbox label="设为默认邮箱地址" name="1"/>-->
<!--            </el-checkbox-group>-->
<!--          </el-form-item>-->
          <!--                保存按钮-->
          <div class="btn-box">
            <el-row class="mb-4" style="justify-content: center;">
              <el-button color="#0052D9" @click="submitEmail">保 存</el-button>
              <el-button type="info" @click="resetForm('emailFormRef')">重 置</el-button>
            </el-row>
          </div>

        </el-form>

      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {regionData} from 'element-china-area-data'
import {ElMessage, ElNotification} from "element-plus";

export default {
  name: "invoice",
  data() {
    return {
      nowPage: 1,
      pageLimit: 3,
      tableTotal: 1,
      invoiceNowPage: 1,
      invoicePageLimit: 5,
      invoiceTableTotal: 1,
      activeName: 'first',
      ruleForm: {
        header: '',
        type: '增值税普通发票',
        code: '',
        bank_code: '',
        bank_address: '',
        bank_name:'',
        phone: ''
      },
      rules: {
        header: [{required: true, message: '请输入发票抬头', trigger: 'blur'}],
        code: [{required: true, message: '请输入 统一社会信代码', trigger: 'blur'}],
        bank_code: [{required: true, message: '请输入基本开户账号', trigger: 'blur'}],
        bank_address: [{required: true, message: '请输入企业场所地址', trigger: 'blur'}],
        bank_name: [{required: true, message: '请输入开户银行名称', trigger: 'blur'}],
        phone: [{required: true, message: '请输入注册固定电话', trigger: 'blur'}],
      },
      emailForm: {
        email: '',
        // checked: []
      },
      emailRules: {
        email: [{required: true, message: '请输入邮箱', trigger: 'blur'}],
        checked: []
      },
      emailAddressForm: {
        name: '',
        region: [],
        address: '',
        phone: '',
        postal_code: '',
        // checked: [],
        selectedOptions: [],
      },
      emailAddressRules: {
        name: [{required: true, message: '请输入收件人', trigger: 'blur'}],
        region: [{required: true, message: '请选择地区', trigger: 'blur'}],
        address: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
        phone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {type: 'number', message: '手机号必须是数字'}
        ],
        postal_code: [{required: true, message: '请输入邮政编码', trigger: 'blur'}],
        selectedOptions: [{required: true, message: '请选择地区', trigger: 'blur'}]
      },
      options: regionData,
      selectedAreaText: '',
      typeOptions: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '已开票',
          value: '2'
        }, {
          label: '待开票',
          value: '0'
        }, {
          label: '开票中',
          value: '1'
        }
      ],
      search: '',
      state: '',
      userTableData: [],
      IsDrawBill: false,
      invoiceTableData:[],
      stepsActive:1,
      headerTableData:[
        {
          date: '2016-05-03',
          address: 'No. 189, Grove St, Los Angeles',
        }
      ],
      invoicingForm:{
        type:'0',
        des:''
      },
      invoiceAmount:{},
      selectionChangeList:[]

    }
  },
  methods: {
    confirmSubmission(){
      let renew_list = [];
      for (let i = 0; i < this.selectionChangeList.length; i++) {
        renew_list.push(this.selectionChangeList[i].id)
      }
      let addInvoice = {
        invoice_header:this.ruleForm.header,
        invoice_type:this.ruleForm.type,
        invoice_code:this.ruleForm.code,
        invoice_price:this.amountToBeInvoiced,
        bank_name:this.ruleForm.bank_name,
        bank_code:this.ruleForm.bank_code,
        com_address:this.ruleForm.bank_address,
        com_phone:this.ruleForm.phone,
        to_type:this.invoicingForm.type,
        email:this.emailForm.email,
        post_name:this.emailAddressForm.name,
        post_phone:this.emailAddressForm.phone,
        post_address:`${this.emailAddressForm.region_cn} / ${this.emailAddressForm.address}`,
        note:this.invoicingForm.des,
        renew_list:renew_list,
      }
      vaildInterface("wechat/api.php","addInvoice",addInvoice,
          "01").then((res)=>{
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        ElMessage.success(res.msg);
        //提交完 跳到主页面
        this.IsDrawBill = false;
        this.stepsActive = 1;
      //  可开金额更新
        this.getInvoiceAmount();
      //  首页表格
        this.getInvoiceList();
      }).catch((result=>{
        //数据返回失败
      }));

    },
    handleRowClick(row, column, event){
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    getInvoiceAmount(){
      vaildInterface("wechat/api.php","getInvoiceAmount",{},
          "01").then((res)=>{
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        this.invoiceAmount = res.data.detail;
      }).catch((result=>{
        //数据返回失败
      }));
    },
    //格式化金额(后面两位小数会四舍五入)
    moneyFormats(value){
      let a=Number(value);  //转为数字格式
      let b=a.toLocaleString();
      return b
    },
    getInvoiceList(){
      vaildInterface("wechat/api.php","invoiceList",{
        keyword:'invoiceList',
        limit: this.pageLimit.toString(),
        page: this.nowPage.toString(),
        search:this.search,
        invoice:this.state
      },"01").then((res)=>{
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        this.userTableData = res.data.list;
        this.tableTotal = parseInt(res.data.total);
      }).catch((result=>{
        //数据返回失败
      }));
    },
    nextSteps(){
      if(this.selectionChangeList.length === 0){
        ElMessage.error('请选择开票订单');
        return
      }
      this.stepsActive = 2;
    },
    lastSteps(){
      this.IsDrawBill = false;
    },
    cancelBtn(){
      this.stepsActive = 1;
    },
    selectBtn(){
      this.getInvoiceList();
    },
    resetBtn(){
      this.search = '';
      this.state = '';
    },
    handleSelectionChange(val){
      this.selectionChangeList = val;
    },
    drawBillClick() {
      this.IsDrawBill = true;
      this.selectionChangeList = [];
      this.invoicingForm = {
        type:'0',
        des:''
      }
      //待开发票表表格
      this.getInvoiceListType();
    },
    handleCurrentChange(val) {
      this.getInvoiceList();
    },
    invoiceHandleCurrentChange(){
      this.getInvoiceListType();
    },
    routerCard(name) {
      this.activeName = name;
    },
    //发票抬头提交
    submitHeader() {
      this.$refs.ruleFormRef.validate((valid, fields) => {
        if (valid) {
          vaildInterface("wechat/api.php","addInvoiceInfo",this.ruleForm,
              "01").then((res)=>{
            if (!res.success) {
              ElMessage.error(res.msg);
              return
            }
            ElMessage.success(res.msg);
            this.toUpdateAll();
          }).catch((result=>{
            //数据返回失败
          }));
        } else {
          console.log('error submit!')
        }
      })
    },
    //获取抬头信息
    getInvoiceInfo(){
      vaildInterface("wechat/api.php","getInvoiceInfo",{},
          "01").then((res)=>{
        if (!res.success) {
          // ElMessage.error(res.msg);
          return
        }
        this.ruleForm = res.data.list[0];
      }).catch((result=>{
        //数据返回失败
      }));
    },
    //邮箱提交
    submitEmail() {
      this.$refs.emailFormRef.validate((valid, fields) => {
        if (valid) {
          vaildInterface("wechat/api.php","addInvoiceEmail",this.emailForm,
              "01").then((res)=>{
            if (!res.success) {
              ElMessage.error(res.msg);
              return
            }
            ElMessage.success(res.msg);
            this.toUpdateAll();
          }).catch((result=>{
            //数据返回失败
          }));
        } else {
          console.log('error submit!')
        }
      })
    },
    //获取邮箱
    getInvoiceEmail(){
      vaildInterface("wechat/api.php","getInvoiceEmail",{},
          "01").then((res)=>{
        if (!res.success) {
          // ElMessage.error(res.msg);
          return
        }
        this.emailForm = res.data.list[0];
      }).catch((result=>{
        //数据返回失败
      }));
    },
    //获取邮寄地址
    getInvoiceAddress(){
      vaildInterface("wechat/api.php","getInvoiceAddress",{},
          "01").then((res)=>{
        if (!res.success) {
          // ElMessage.error(res.msg);
          return
        }
        this.emailAddressForm = res.data.list[0];
        this.emailAddressForm.phone = Number(this.emailAddressForm.phone);
        this.emailAddressForm.region = JSON.parse(this.emailAddressForm.region) || [];
        this.selectedAreaText = this.emailAddressForm.region_cn;

      }).catch((result=>{
        //数据返回失败
      }));
    },
    //更新 所有信息
    toUpdateAll(){
      //获取抬头信息
      this.getInvoiceInfo();
      //获取邮寄地址
      this.getInvoiceAddress();
    //  获取邮箱
      this.getInvoiceEmail();
    },
    submitEmailAddress() {
      this.$refs.emailAddressFormRef.validate((valid, fields) => {
        if (valid) {
          //selectedAreaText
          this.emailAddressForm.region_cn = this.selectedAreaText;
          vaildInterface("wechat/api.php","addInvoiceAddress",this.emailAddressForm,
              "01").then((res)=>{
            if (!res.success) {
              ElMessage.error(res.msg);
              return
            }
            ElMessage.success(res.msg);
            this.toUpdateAll();
          }).catch((result=>{
            //数据返回失败
          }));
        } else {
          console.log('error submit!')
        }
      })
    },
    resetForm(fomrName) {
      this.$refs[fomrName].resetFields();
      if (fomrName === 'emailAddressFormRef') {
        this.emailAddressForm.region = [];
        this.selectedAreaText = '';
      }
    },
    handleChange(value) {
      // console.log(value);
      this.selectedAreaText = this.$refs.cascaderArea.inputValue;
      // console.log(this.$refs.cascaderArea.inputValue)
    },
    getInvoiceListType(){
      vaildInterface("wechat/api.php","invoiceList",{
        keyword:'invoiceList',
        limit: this.invoicePageLimit.toString(),
        page: this.invoiceNowPage.toString(),
        invoice:'0'
      },"01").then((res)=>{
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        this.invoiceTableData = res.data.list;
        this.invoiceTableTotal = parseInt(res.data.total);
      }).catch((result=>{
        //数据返回失败
      }));
    },
  },
  mounted() {
    this.toUpdateAll();
    //获取待开票列表
    this.getInvoiceList();
    //开票金额
    this.getInvoiceAmount();
  },
  computed:{
    historicalAmount(){
      let amount = Number(this.invoiceAmount.amount);
      let surplus = Number(this.invoiceAmount.surplus);
      return amount - surplus
    },
    amountToBeInvoiced(){
      let theSum = 0;
      let unitPrice = 0;
      for (let i = 0; i < this.selectionChangeList.length; i++) {
        unitPrice = Number(this.selectionChangeList[i].price);
        if(!isNaN(unitPrice)){
          theSum = theSum + unitPrice;
        }
      }
      return theSum
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}

.item-title-line {
  font-weight: bold;
  color: #333333;
  border-bottom: #E7E7E7 1px solid;
  width: 100%;
  padding-bottom: 15px;
}

.cent-ruleForm {
  width: 60%;
}

.btn-box {
  /*position: absolute;*/
  /*bottom: 15px;*/
  /*right: 6px;*/
}

.kpjl-border {
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  border: #E7E7E7 solid 1px;
  display: flex;
  flex-wrap: wrap;
  color: #333333;
  font-size: 18px;
}

.kpjl-item {
  width: 25%;
  text-align: left;
}

.text-grey {
  color: #999999;
}

.item-p {
  margin-top: 20px;
}

.blue-p {
  color: #0052D9;
}

.black-p{
  color: #303133;
  font-weight: bold;
}

.symbol {
  margin-right: 30px;
}

.item-flex-centet {
  width: 50%;
  display: flex;
  font-size: 16px;
  align-items: center;
}

.table-div {
  margin-top: 30px;
  position: relative;
  border-top: #C7C7C7 solid 1px;
  box-sizing: border-box;
  padding-top: 15px;
  height: 360px;
  overflow-y: auto;
}

.title-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tool-item {
  width: 30%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.tool-label {
  width: 80px;
  min-width: 80px;
  color: #303133;
}

.department-table {
  width: 100%;
  height: calc(100% - 120px);
  overflow: auto;
  padding-top: 20px;
  box-sizing: border-box;
  position: relative;
}

.pagination-box {
  position: absolute;
  right: 15px;
  bottom: 25px;
  z-index: 99;
}
.pagination-flex{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-p{
  font-weight: bold;
  color: #303133;
  margin-bottom: 15px;
}
</style>