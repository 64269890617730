<template>
  <div class="item-title-line">
    续费审核
  </div>
  <div class="ctent" style="position: relative">
<!--    <div class="title-tool department-tool">-->

<!--      <div style="display: flex;width: 100%;">-->
<!--        <div class="tool-item">-->
<!--          <div class="tool-label">-->
<!--            搜索：-->
<!--          </div>-->
<!--          <el-input v-model="search" placeholder="输入标题、作者搜索" style="width: 100%;"/>-->
<!--        </div>-->

<!--        <div class="tool-item">-->
<!--          <div class="tool-label">-->
<!--            班级：-->
<!--          </div>-->
<!--          <el-cascader v-model="schoolClass" :options="njOptions"-->
<!--                       :props="{-->
<!--					 checkStrictly: true,-->
<!--					 value:'id',-->
<!--					 label:'name'-->
<!--					}"-->
<!--                       ref="cascader"-->
<!--                       @change="setClass"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="tool-item" style="color: #ffffff;">-->
<!--          <el-button color="#2968D4" type="primary" @click="selectBtn">查 询</el-button>-->
<!--          <el-button @click="resetBtn">重置</el-button>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
    <div class="department-table">
      <el-table :data="userTableData" style="width: 100%" :row-style="{height:'65px'}">
        <el-table-column prop="renew_type" label="付费种类" align="center" show-overflow-tooltip/>
        <el-table-column prop="price" label="总价格" align="center"/>
        <el-table-column prop="add_time" label="发布时间" align="center"/>
        <el-table-column label="操作">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <button class="el-button el-button--text el-button--small" type="button"
                      @click="showDepartment(scope.row)" >
                <span style="color: #409eff;">审核</span>
              </button>
              <!-- <el-popconfirm title="确定要删除吗?" @confirm="delDepartment(scope.row)">
                 <template #reference>
                   <button class="el-button el-button--text el-button--small" type="button">
                     <span style="color: #f56c6c;">删除</span>
                   </button>
                 </template>
               </el-popconfirm> -->
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="job_number" label="工号"  align="center"/> -->
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination-box">
      <el-pagination
          v-model:currentPage="nowPage"
          v-model:page-size="pageLimit"
          layout="total,prev, pager, next, jumper"
          :total="tableTotal"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>
  <el-dialog
      v-model="dialogVisible"
      title="续费审核"
      width="50%"
      @closed="VisibleClosed"
  >
    <el-form :model="detailsData" label-width="120px" :rules="rules" ref="ruleFormRef">
      <el-form-item label="付费种类">
        <el-input v-model="detailsData.renew_type" type="textarea"  disabled />
      </el-form-item>
      <el-form-item label="总金额（元）">
        <el-input v-model="detailsData.price" disabled  />
      </el-form-item>
      <el-form-item label="是否通过审核" prop="examine">
        <el-radio-group v-model="detailsData.examine">
          <el-radio label="1">审核通过</el-radio>
          <el-radio label="0">审核不通过</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitFrom">
          提交
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!--11-->
</template>

<script>
import {
  ElMessage,
  ElLoading
} from 'element-plus'
export default {
  name: 'RenewalApproval',
  data() {
    return {
      dialogVisible:false,
      isSubmit:false,
      search: '',
      userTableData: [],
      nowPage: 1,
      pageLimit: 8,
      tableTotal: 0,
      schoolClass:'',
      njOptions:[],
      setClassText:'',
      detailsData:{},
      rules:{
        examine:[{ required: true, message: '请选择是否通过审核！', trigger: 'blur' }]
      }
    }
  },
  methods: {
    VisibleClosed(){
      this.$refs.ruleFormRef.resetFields();
      this.detailsData = {};
    },
    submitFrom(formEl){
      this.$refs.ruleFormRef.validate((valid, fields) => {
        if (valid) {
          vaildInterface("wechat/api.php","confirmRenew",{
                result:this.detailsData.examine,
                renew_id:this.detailsData.renew_id
              },
              "01").then((res)=>{
            if (!res.success) {
              ElMessage.error(res.msg);
              return
            }
            ElMessage.success(res.msg);
            this.dialogVisible = false;
            this.getUserTableData();
          }).catch((result=>{
            //数据返回失败
          }));
        } else {
          console.log('error submit!')
        }
      })
    },
    showDepartment(data){
      this.dialogVisible = true;
      this.detailsData = data;
    },
    delDepartment(data){

    },
    handleCurrentChange(data) {
      this.nowPage = data;
      // 用户列表
      this.getUserTableData();
    },
    getUserTableData() {
      vaildInterface("wechat/api.php","unconfirmedRenewList",{
        keyword:'unconfirmedRenewList',
        limit: this.pageLimit.toString(),
        page: this.nowPage.toString(),
      },"01").then((res)=>{
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        this.userTableData = res.data.list;
        this.tableTotal = parseInt(res.data.total);
      }).catch((result=>{
        //数据返回失败
      }));
    },
    selectBtn() {
      // 用户列表
      this.getUserTableData();
    },
    resetBtn() {
      this.search = '';
      this.schoolClass = '';
      this.setClassText = '';
    },
  },
  mounted() {
    // 用户列表
    this.getUserTableData();
  }
}
</script>

<style scoped>
.department-table {
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}

.pagination-box {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.ctent {
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 32px);
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
}

.title-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tool-item {
  width: 26%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.tool-label {
  width: 80px;
  min-width: 80px;
  color: #303133;
}
.img-flex{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.demo-image__preview{
  width: 12.5%;
  box-sizing: border-box;
  padding: 0 15px 15px 0;
}
.item-title-line {
  font-weight: bold;
  color: #333333;
  border-bottom: #E7E7E7 1px solid;
  width: 100%;
  padding-bottom: 15px;
}
</style>