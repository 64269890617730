<template>
  <div class="container">
    <div class="item-title-line">
      系统有效期
    </div>
  </div>
</template>

<script>
export default {
  name: "endtime",
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}

.item-title-line {
  font-weight: bold;
  color: #333333;
  border-bottom: #E7E7E7 1px solid;
  width: 100%;
  padding-bottom: 15px;
}
</style>