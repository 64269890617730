<template>
  <div class="item-title-line">
    发票审核
  </div>
  <div class="ctent" style="position: relative">
    <!--    <div class="title-tool department-tool">-->

    <!--      <div style="display: flex;width: 100%;">-->
    <!--        <div class="tool-item">-->
    <!--          <div class="tool-label">-->
    <!--            搜索：-->
    <!--          </div>-->
    <!--          <el-input v-model="search" placeholder="输入标题、作者搜索" style="width: 100%;"/>-->
    <!--        </div>-->

    <!--        <div class="tool-item">-->
    <!--          <div class="tool-label">-->
    <!--            班级：-->
    <!--          </div>-->
    <!--          <el-cascader v-model="schoolClass" :options="njOptions"-->
    <!--                       :props="{-->
    <!--					 checkStrictly: true,-->
    <!--					 value:'id',-->
    <!--					 label:'name'-->
    <!--					}"-->
    <!--                       ref="cascader"-->
    <!--                       @change="setClass"-->
    <!--          />-->
    <!--        </div>-->

    <!--        <div class="tool-item" style="color: #ffffff;">-->
    <!--          <el-button color="#2968D4" type="primary" @click="selectBtn">查 询</el-button>-->
    <!--          <el-button @click="resetBtn">重置</el-button>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </div>-->
    <div class="department-table">
      <el-table :data="userTableData" style="width: 100%" :row-style="{height:'65px'}">
        <el-table-column prop="invoice_header" label="发票抬头" align="center" show-overflow-tooltip/>
        <el-table-column prop="invoice_type" label="发票类型" align="center"/>
        <el-table-column prop="invoice_price" label="总价格（元）" align="center"/>
        <el-table-column prop="post_name" label="开票人" align="center"/>
        <el-table-column prop="post_phone" label="开票人电话" align="center"/>
        <el-table-column prop="post_address" label="收取地址" align="center" show-overflow-tooltip/>
        <el-table-column prop="add_time" label="提交时间" align="center"/>
        <el-table-column label="操作">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <button class="el-button el-button--text el-button--small" type="button"
                      @click="showDepartment(scope.row)" >
                <span style="color: #409eff;">审核</span>
              </button>
              <!-- <el-popconfirm title="确定要删除吗?" @confirm="delDepartment(scope.row)">
                 <template #reference>
                   <button class="el-button el-button--text el-button--small" type="button">
                     <span style="color: #f56c6c;">删除</span>
                   </button>
                 </template>
               </el-popconfirm> -->
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="job_number" label="工号"  align="center"/> -->
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination-box">
      <el-pagination
          v-model:currentPage="nowPage"
          v-model:page-size="pageLimit"
          layout="total,prev, pager, next, jumper"
          :total="tableTotal"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>
  <el-dialog
      v-model="dialogVisible"
      title="续费审核"
      width="50%"
      @closed="VisibleClosed"
  >
    <div style="max-height: 500px;overflow-y: auto;box-sizing: border-box;padding-right: 8px">

      <el-form :model="detailsData" label-width="120px" :rules="rules" ref="ruleFormRef">
        <el-form-item label="发票抬头">
          <el-input v-model="detailsData.invoice_header" disabled  />
        </el-form-item>
        <el-form-item label="	发票类型">
          <el-input v-model="detailsData.invoice_type" disabled  />
        </el-form-item>
        <el-form-item label="发票税号">
          <el-input v-model="detailsData.invoice_code" disabled  />
        </el-form-item>
        <el-form-item label="开票金额（元）">
          <el-input v-model="detailsData.invoice_price" disabled  />
        </el-form-item>
        <el-form-item label="开户行">
          <el-input v-model="detailsData.bank_name" type="textarea"  disabled />
        </el-form-item>
        <el-form-item label="	开户行号">
          <el-input v-model="detailsData.bank_code" disabled  />
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="detailsData.com_address" type="textarea"  disabled />
        </el-form-item>
        <el-form-item label="	公司电话">
          <el-input v-model="detailsData.com_phone" disabled  />
        </el-form-item>
        <el-form-item label="电子或纸质发票">
          <el-input v-model="detailsData.to_type" disabled  />
        </el-form-item>
        <el-form-item label="	接受邮箱">
          <el-input v-model="detailsData.email" disabled  />
        </el-form-item>
        <el-form-item label="	接收人姓名">
          <el-input v-model="detailsData.post_name" disabled  />
        </el-form-item>
        <el-form-item label="	接收人电话">
          <el-input v-model="detailsData.post_phone" disabled  />
        </el-form-item>
        <el-form-item label="收件人地址">
          <el-input v-model="detailsData.post_address" type="textarea"  disabled />
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="detailsData.note" type="textarea"  disabled />
        </el-form-item>
        <el-form-item label="是否开票" prop="examine">
          <el-radio-group v-model="detailsData.examine">
            <el-radio label="1">同意开票</el-radio>
            <el-radio label="0">不予开票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="物流公司">
          <el-input v-model="detailsData.express_name"/>
        </el-form-item>
        <el-form-item label="物流单号">
          <el-input v-model="detailsData.express_code"  />
        </el-form-item>
      </el-form>

    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitFrom">
          提交
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!--11-->
</template>

<script>
import {
  ElMessage,
  ElLoading
} from 'element-plus'
export default {
  name: 'InvoiceApproval',
  data() {
    return {
      dialogVisible:false,
      isSubmit:false,
      search: '',
      userTableData: [],
      nowPage: 1,
      pageLimit: 8,
      tableTotal: 0,
      schoolClass:'',
      njOptions:[],
      setClassText:'',
      detailsData:{},
      rules:{
        examine:[{ required: true, message: '请选择是否已开票！', trigger: 'blur' }]
      }
    }
  },
  methods: {
    VisibleClosed(){
      this.$refs.ruleFormRef.resetFields();
      this.detailsData = {};
    },
    submitFrom(formEl){
      this.$refs.ruleFormRef.validate((valid, fields) => {
        if (valid) {
          vaildInterface("wechat/api.php","confirmInvoice",{
                result:this.detailsData.examine,
                invoice_id:this.detailsData.invoice_id,
                express_name:this.detailsData.express_name,
                express_code:this.detailsData.express_code,
              },
              "01").then((res)=>{
            if (!res.success) {
              ElMessage.error(res.msg);
              return
            }
            ElMessage.success(res.msg);
            this.dialogVisible = false;
            this.getUserTableData();
          }).catch((result=>{
            //数据返回失败
          }));
        } else {
          console.log('error submit!')
        }
      })
    },
    showDepartment(data){
      this.dialogVisible = true;
      this.detailsData = data;
      this.detailsData.to_type = this.detailsData.to_type == '0'?'电子发票普票':'纸质发票';
    },
    delDepartment(data){

    },
    handleCurrentChange(data) {
      this.nowPage = data;
      // 用户列表
      this.getUserTableData();
    },
    getUserTableData() {
      vaildInterface("wechat/api.php","unconfirmedInvoiceList",{
        keyword:'unconfirmedInvoiceList',
        limit: this.pageLimit.toString(),
        page: this.nowPage.toString(),
      },"01").then((res)=>{
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        this.userTableData = res.data.list;
        this.tableTotal = parseInt(res.data.total);
      }).catch((result=>{
        //数据返回失败
      }));
    },
    selectBtn() {
      // 用户列表
      this.getUserTableData();
    },
    resetBtn() {
      this.search = '';
      this.schoolClass = '';
      this.setClassText = '';
    },
  },
  mounted() {
    // 用户列表
    this.getUserTableData();
  }
}
</script>

<style scoped>
.department-table {
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}

.pagination-box {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.ctent {
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 32px);
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
}

.title-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tool-item {
  width: 26%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.tool-label {
  width: 80px;
  min-width: 80px;
  color: #303133;
}
.img-flex{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.demo-image__preview{
  width: 12.5%;
  box-sizing: border-box;
  padding: 0 15px 15px 0;
}
.item-title-line {
  font-weight: bold;
  color: #333333;
  border-bottom: #E7E7E7 1px solid;
  width: 100%;
  padding-bottom: 15px;
}
</style>