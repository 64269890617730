<template>
  <div class="container">
    <div class="item-title-line">
      续费管理
    </div>

    <div class="title-tool department-tool">

      <div style="display: flex;width: 100%;">
        <div class="tool-item">
          <div class="tool-label">
            搜索：
          </div>
          <el-input v-model="search" placeholder="输入公众号名称" style="width: 100%;"/>
        </div>

        <div class="tool-item">
          <div class="tool-label">
            续费状态：
          </div>
          <el-select v-model="state" style="width: 100%" placeholder="选择续费状态">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>

        <div class="tool-item" style="color: #ffffff;">
          <el-button color="#2968D4" type="primary" @click="selectBtn">查 询</el-button>
          <el-button @click="resetBtn">重置</el-button>
          <el-button type="primary" @click="renewBtn">续费</el-button>
        </div>
      </div>


    </div>

    <div class="department-table">
      <el-table :data="userTableData" style="width: 100%" :row-style="{height:'65px'}">
        <el-table-column prop="app_name" label="续费名称" align="center" show-overflow-tooltip/>
        <el-table-column label="激活金额">
          <template #default="scope">
              <span>
                {{scope.row.price}} 元
              </span>
          </template>
        </el-table-column>
        <el-table-column label="公众号类型">
          <template #default="scope">
            <el-tag v-if="scope.row.type === '1'">订阅号</el-tag>
            <el-tag v-else>服务号</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="激活状态">
          <template #default="scope">
            <el-tag v-if="scope.row.is_use === '2'" type="success">已激活</el-tag>
            <el-tag v-else-if="scope.row.is_use === '1'" type="warning">处理中</el-tag>
            <el-tag v-else type="info">未激活</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="note" label="备注" align="center" show-overflow-tooltip />
        <el-table-column prop="add_time" label="添加时间" align="center" show-overflow-tooltip/>
        <!--          <el-table-column label="操作">-->
        <!--            <template #default="scope">-->
        <!--              <div style="display: flex; align-items: center">-->
        <!--                <button class="el-button el-button&#45;&#45;text el-button&#45;&#45;small" type="button"-->
        <!--                        @click="showDepartment(scope.row)" >-->
        <!--                  <span style="color: #409eff;">查看</span>-->
        <!--                </button>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination-box">
      <el-pagination
          v-model:currentPage="nowPage"
          v-model:page-size="pageLimit"
          layout="total,prev, pager, next, jumper"
          :total="tableTotal"
          @current-change="handleCurrentChange"
      />
    </div>

  </div>
  <!--  续费弹窗-->
  <el-dialog
      v-model="centerDialogVisible"
      title="续费"
      width="70%"
      align-center
      @closed="dialogClosed"
  >
    <div style="max-height: 600px;overflow-y: auto;box-sizing: border-box;padding-right: 8px">

      <el-descriptions
          class="margin-top"
          title="收款信息"
          :column="3"
          border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <user/>
              </el-icon>
              公司名称
            </div>
          </template>
          山东智诚大数据服务有限公司
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <iphone/>
              </el-icon>
              联系电话
            </div>
          </template>
          0531-55556730
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <location/>
              </el-icon>
              地址
            </div>
          </template>
          山东省济南市历下区花园东路3333号祥泰汇东国际大厦1号楼1124室
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <tickets/>
              </el-icon>
              公司账号
            </div>
          </template>
          15158901040002783
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <office-building/>
              </el-icon>
              银行名称
            </div>
          </template>
          中国农业银行股份有限公司济南文化城支行
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <office-building/>
              </el-icon>
              银行联号
            </div>
          </template>
          103451015896
        </el-descriptions-item>
      </el-descriptions>
      <!--     预算价格-->
      <div class="el-descriptions__header" style="margin-top: 16px">
        <div class="el-descriptions__title">价格明细</div>
        <div class="el-descriptions__extra">
        </div>
      </div>

      <el-transfer v-model="priceValue" :data="priceCatalogueData"
                   :titles="['续费目录', '要续费的明细']"
                   :props="{
                key: 'app_guid',
                label: 'app_name',
              }"
                   @change="transferChange"
      />

      <el-table
          :data="priceTableData"
          border
          :summary-method="getSummaries"
          show-summary
          style="width: 100%; margin-top: 20px"
      >
        <el-table-column prop="renew_type" label="付费类型" />
        <el-table-column prop="price" label="单价(元)" />
        <el-table-column prop="amount1" label="数量">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              1
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount2" label="时长" >
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span v-if="scope.row.app_guid">永久激活</span>
              <span v-else>一年有效期</span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-alert
          style="margin-top: 16px;margin-bottom: 16px;font-size: 13px"
          title="根据收款信息和价格明细进行对公转账后点击下方 【我已续费】 按钮，我们将进行核实"
          type="success"
          :closable="false"
      />

    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="iHaveRenewed">
          我已续费
        </el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import {ElMessage,ElNotification} from "element-plus";

export default {
  name: "renew",
  data() {
    return {
      centerDialogVisible: false,
      nowPage: 1,
      pageLimit: 9,
      tableTotal: 1,
      search: '',
      state: '',
      options: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '已激活',
          value: '2'
        }, {
          label: '处理中',
          value: '1'
        }, {
          label: '未激活',
          value: '0'
        }
      ],
      userTableData: [],
      priceValue:[],
      priceCatalogueData:[],
      priceTableData:[]


    }
  },
  methods: {
    iHaveRenewed(){
      this.centerDialogVisible = false;
      vaildInterface("wechat/api.php","renew",{
        keyword:'renew',
        renewList:this.priceTableData,
      },"01").then((res)=>{
        console.log(res);
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        ElNotification({
          title: '提交成功',
          message: '已收到请求，系统审核中请稍后查看',
          type: 'success',
        })
        this.getUserTableData();
      }).catch((result=>{
        //数据返回失败
      }));
    },
    getSummaries(param) {
      // columns 是所有列的一个数组
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        // column 是每一列，index 表示列的索引，第一列的时候不计算合计，显示自定义的文字内容
        if (index === 0) {
          sums[index] = '总价';
          return;
        }
        //下面这一坨代码就是把每一列中的所有单元格中的值转化成Number型，
        //然后对转化后的结果进行判断，如果是number型，则进行累加，
        //如果NaN型，说明单元格里是一些无法转化成number型的值，则返回自定义的内容
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' 元';
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
  },
    transferChange(value, direction, movedKeys){
      console.log(value);
      this.priceTableData = [];
      for (let i = 0; i < this.priceCatalogueData.length; i++) {
        for (let j = 0; j < value.length; j++) {
          if(this.priceCatalogueData[i].app_guid === value[j]){
            this.priceTableData.push(this.priceCatalogueData[i]);
          }
        }
      }
      console.log(this.priceTableData)
    },
    dialogClosed(){
      this.priceValue = [];
      this.priceTableData = [];
    },
    renewBtn() {
      //  穿梭窗列表
      this.getPriceList();
      this.centerDialogVisible = true;
    },
    selectBtn() {
      this.getUserTableData();
    },
    resetBtn() {
      this.state = '';
      this.search = '';
    },
    handleCurrentChange(data) {
      this.nowPage = data;
      // 用户列表
      this.getUserTableData();
    },
    getUserTableData(){
      vaildInterface("wechat/api.php","renew_list",{
        keyword:'renew_list',
        limit: this.pageLimit.toString(),
        page: this.nowPage.toString(),
        search:this.search,
        is_use:this.state
      },"01").then((res)=>{
        console.log(res);
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        this.userTableData = res.data.list;
        this.tableTotal = parseInt(res.data.total);
      }).catch((result=>{
        //数据返回失败
      }));
    },
    getPriceList(){
      vaildInterface("wechat/api.php","renew_list2",{
        keyword:'renew_list2',
      },"01").then((res)=>{
        console.log(res);
        if (!res.success) {
          ElMessage.error(res.msg);
          return
        }
        this.priceCatalogueData = res.data.list;
      }).catch((result=>{
        //数据返回失败
      }));
    }
  },
  mounted() {
    this.getUserTableData();
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}

.item-title-line {
  font-weight: bold;
  color: #333333;
  border-bottom: #E7E7E7 1px solid;
  width: 100%;
  padding-bottom: 15px;
}

.title-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tool-item {
  width: 30%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.tool-label {
  width: 80px;
  min-width: 80px;
  color: #303133;
}

.department-table {
  width: 100%;
  height: calc(100% - 120px);
  overflow: auto;
  padding-top: 20px;
  box-sizing: border-box;
  position: relative;
}

.pagination-box {
  position: absolute;
  right: 15px;
  bottom: 10px;
  z-index: 99;
}
</style>