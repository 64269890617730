<template>
	 <p class="title-p">系统设置</p>
  <el-row :gutter="20" style="height: calc(100% - 31px)">
    <el-col :span="4">
      <div class="item-car">

        <div class="left-nav">
          <div class="cent">
            <section>
              <p class="left-p"
                 @click="leftClick(item.title)"
                 :class="{'active':leftNowTitle === item.title}"
                 v-for="(item,index) in leftNavListFilter" :key="index">{{ item.title }}</p>
            </section>
          </div>
        </div>

      </div>
    </el-col>
    <el-col :span="20">
      <div class="ctent">
<!--        组件-->
        <renew v-if="leftNowTitle === '续费管理'"></renew>
        <RenewalApproval v-else-if=" leftNowTitle === '续费审批' "></RenewalApproval>
        <invoice v-else-if=" leftNowTitle === '发票管理' "></invoice>
        <endtime v-else-if=" leftNowTitle === '系统有效期' "></endtime>
        <invoiceapproval v-else-if=" leftNowTitle === '发票审核' "></invoiceapproval>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import renew  from "@/views/systemSettings/renew";
import invoice  from "@/views/systemSettings/invoice";
import endtime  from "@/views/systemSettings/endtime";
import invoiceapproval  from "@/views/systemSettings/InvoiceApproval";
import RenewalApproval  from "@/views/systemSettings/RenewalApproval";
	export default {
		name: "systemSettings",
		components: {
      renew:renew,
      invoice:invoice,
      endtime:endtime,
      invoiceapproval:invoiceapproval,
      RenewalApproval:RenewalApproval
    },
		data(){
			return {
        ucode:JSON.parse(sessionStorage.getItem('ucode')) || '',
        leftNowTitle:'续费管理',
        leftNavList: [
          {
            title: '续费管理'
          },
          {
            title: '续费审批'
          },
          {
            title: '发票管理'
          },
          {
            title: '发票审核'
          }
          // {
          //   title: '系统有效期'
          // }
        ]
			}
		},
		//监听属性 类似于data概念
		computed:{
      leftNavListFilter(){
        let ucode = JSON.parse(sessionStorage.getItem('ucode'));
        return this.leftNavList.filter(function (data){
          if(ucode.account == 'admin'){
            return data
          }else{
            if (data.title !=='续费审批' && data.title !=='发票审核'){
              return data
            }
          }
        })
      }
    },
		//监控data中的数据变化
		watch:{
			
		},
		//方法集合
		methods:{
      leftClick(title) {
        this.leftNowTitle = title;
      },
		},
		//生命周期 - 挂载完成（可以访问DOM元素）
		mounted(){

		}
	}
</script>

<style scoped>
	.ctent {
		  width: 100%;
		  margin: 0 auto;
		  height: 100%;
		  padding: 15px;
		  box-sizing: border-box;
		  background-color: #ffffff;
		  border-radius: 10px;
		}
	.xtsz-title{
		font-size: 21px;
		font-weight: bold;
		color: rgba(0,0,0,0.9);
		text-align: left;
		margin-bottom: 15px;
	}
  .item-car{
    background-color: #fff;
    border-radius: 3px;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  .left-nav {
    width: 100%;
    height: 100%;
  }
  .left-p {
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 10px;
  }

  .left-p.active {
    background-color: #ECF2FE;
    border-radius: 5px;
  }
</style>